import { Box, Typography } from '@mui/material';
import { MaintenanceIcon } from './icons';

export function Maintenance() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <MaintenanceIcon />
      <Typography
        sx={{
          fontSize: '48px',
          fontWeight: '500',
          lineHeight: '50px',
          pt: 6,
          pb: 4,
        }}
      >
        We're making some<br/>changes to our app
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: '300',
          lineHeight: '20px',
          color: '#ACAFB3',
        }}
      >
        Hang tight, Altcoinist will be back online in a bit!
      </Typography>
    </Box>
  );
}
