import { creatorTopUpWalletStep } from '../../../constants/setupAccountSteps';
import useGlobalModal from '../../../hooks/useGlobalModal';
import { useProfileSetupProgress } from '../../../providers/ProfileSetupProgressProvider';
import CreatorSetupCard from '../Cards/CreatorSetupCard';

export const ONBOARDING_TOPUP_WALLET_TITLE =
  'Get ETH on Base to set up your pricing';
export const ONBOARDING_TOPUP_WALLET_DESCRIPTION =
  'Get some ETH on Base to cover gas when setting up your pricing.';

export function TopUpCard() {
  const { showModal } = useGlobalModal();

  const { isTopUpSetupDone } = useProfileSetupProgress();

  const onItemClick = () => {
    showModal({
      modalType: 'TopUpWalletModal',
      modalProps: {
        customTitle: ONBOARDING_TOPUP_WALLET_TITLE,
        customDescription: ONBOARDING_TOPUP_WALLET_DESCRIPTION,
      },
    });
  };

  return (
    <CreatorSetupCard
      key={creatorTopUpWalletStep.key}
      action={onItemClick}
      isDone={isTopUpSetupDone}
      disabled={isTopUpSetupDone}
      title={creatorTopUpWalletStep.title}
      description={creatorTopUpWalletStep.description}
      icon={creatorTopUpWalletStep.icon}
      buttonText={creatorTopUpWalletStep.buttonText}
    />
  );
}
